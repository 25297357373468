export const environment = {
  production: true,
  skipAuthentication: false,
  apiUrl: 'https://api-meditouch.vqtech.nl/v1',
  authClientId: 'f2c3a88d-d781-479e-8eba-27ae0e3138be', // see prod environment as an example for values to test with.
  authAuthority: 'https://login.microsoftonline.com/b8fee708-9049-4737-a962-50f8a6d13711',
  authRedirectUri: 'https://medicatief.vqtech.nl/',
  authLogoutRedirectUri: 'https://medicatief.vqtech.nl/',
  authApiScope: 'api://ea96ae12-4e32-49ca-916e-264387d5a7d6/General',
  appEnv: 'development'
};
