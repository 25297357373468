import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, Provider} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoaderService} from './core/services/loader/loader.service';
import {registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {PaginatorNL_nld} from './util/paginator/paginator-nl';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MomentModule} from 'ngx-moment';
import {environment} from '../environments/environment';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {ThemeService} from './core/services/theme/theme.service';
import {ServiceWorkerModule} from '@angular/service-worker';

registerLocaleData(localeNl);
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from "@azure/msal-angular";
import {MockMsalService} from "./core/services/mock/mock-msal.service";

export function themeFactory(themeService: ThemeService): any {
  return () => themeService.setThemeOnStart();
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.authClientId,
      authority: environment.authAuthority,
      redirectUri: environment.authRedirectUri,
      postLogoutRedirectUri: environment.authLogoutRedirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      loggerOptions: {
        loggerCallback: console.log,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl + '/*', [environment.authApiScope]);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        environment.authApiScope
      ]
    },
    //loginFailedRoute: '/' // Don't have one for this
  };
}

const providers: Provider[] = [{
  provide: MsalService,
  useClass: environment.skipAuthentication ? MockMsalService : MsalService
}];

const imports = [];
const bootstrap = [];

if (!environment.skipAuthentication) {
  providers.push({
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
      }, {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      MsalGuard,
      MsalBroadcastService
  );

  imports.push(MsalModule);
  bootstrap.push(MsalRedirectComponent);
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('safety-worker.js', {enabled: environment.production}),
    MomentModule,
    ...imports
  ],
  providers: [
    LoaderService,
    {
      provide: MatPaginatorIntl,
      useValue: PaginatorNL_nld()
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'nl-NL'
    },
    ...providers
  ],
  bootstrap: [
      AppComponent,
      ...bootstrap
  ]
})
export class AppModule {
}
