import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {environment} from "../environments/environment";

const guards: any[] = environment.skipAuthentication ? [] : [MsalGuard];
const routes: Routes = [
  {
    canActivate: guards,
    path: '',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
